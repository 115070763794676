define("a24-ember-candidate-profile/components/candidate-references", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-references", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateReferences, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateReferences.default,
        objSettingsRestService: null,
        sCandidateId: null,
        objCandidateRefereeRestService: null,

        _bLoading: false,
        _bAllowAddEdit: false,
        _bServerError: false,
        _bIsAdd: false,
        _bShowAddEditRefereePopup: false,
        _bAddEditRefereePopupLoading: false,
        _bShowUnsavedData: false,
        _bIsProfessionalType: true,
        _bIsUkSaCountry: false,
        _bEmployerIsToDate: false,
        _bRefereeIsToDate: false,
        _bServiceCallsDone: false,
        _bShowDeleteConfirm: false,
        _bPopupDeleteLoading: false,
        _bEmployerContactNumberInputsReady: false,
        _bWorkContactNumberInputsReady: false,
        _bMobileContactNumberInputsReady: false,
        _bFaxNumberInputsReady: false,

        _sSelectedCountryCode: null,

        _arrReloadActions: null,
        _objSelectedReferenceType: null,
        _arrReferenceTypes: null,
        _sRefereeId: null,
        _objOriginalData: null,
        _sAddEditRefereeHeader: null,
        _arrCandidateReferees: null,
        _arrDisplayCandidateReferees: null,
        _objCandidateRefereeServicePromise: null,
        _objSpecialAddEditRefereePopupAction: null,
        _arrCountries: null,
        _funcGetCountriesSuggestions: null,
        _objEndDateMaxDate: null,
        _objStartDateMaxDate: null,
        _funcGetCountiesSuggestions: null,
        _arrCounties: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _bIsAgency: Ember.computed("objCandidateRefereeRestService.sContext", function () {
            return this.get("objCandidateRefereeRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY");
        }),

        _bIsAgencyGroup: Ember.computed("objCandidateRefereeRestService.sContext", function () {
            return this.get("objCandidateRefereeRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP");
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var sCountryId = this.get("_objUserSession.objContextDetails.sCountryId");

            var sCountryCode = sCountryId === "710" ? "ZA" : sCountryId === "840" ? "US" : "GB";
            // default country code is that of the context details
            this.set("_sSelectedCountryCode", sCountryCode);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._getCandidateReferences();
                }
            }]);

            this.set("_objSpecialAddEditRefereePopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupRefereeAddEditCancel();
                }
            });

            this._setCountriesDropdown();
            this._setupValidations();

            var objStartDateMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            this.set("_objStartDateMaxDate", objStartDateMaxDate);

            var objEndDateMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            this.set("_objEndDateMaxDate", objEndDateMaxDate);

            this.set("_arrReferenceTypes", [{
                sLabel: this.get("_objCandidateProfileStringsService").getString("character"),
                sValue: this.get("_objCandidateProfileEnumsService.referenceTypes.CHARACTER.backend")
            }]);

            this._getCandidateReferences();
        },

        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            this._super.apply(this, arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            if (sLookup === "objChildren.type") {
                if (this.get(sLookup + ".mValue.sValue") !== this.get("_objCandidateProfileEnumsService.referenceTypes.CHARACTER.backend")) {
                    this.set("_bIsProfessionalType", true);
                } else {
                    this.set("_bIsProfessionalType", false);
                }
            } else if (sLookup === "objChildren.employer.address.country_code") {
                var objCountryCode = this.get("objChildren")["employer.address.country_code"];

                if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue)) {
                    var sCountryCode = objCountryCode.mValue.sValue;
                    this._setCountiesFromCountryCode(sCountryCode);
                    this.set("_sSelectedCountryCode", sCountryCode);

                    var objPostCode = this.get("objChildren")["employer.address.post_code"];

                    objPostCode.get("arrValidation").clear();
                    objPostCode.set("funcValidationRequired", null);

                    if (sCountryCode === "UK" || sCountryCode === "GB" || sCountryCode === "US") {
                        a24Validation.addRequired(objPostCode, true);
                        a24Validation.addPostCode(objPostCode, sCountryCode);
                    } else {
                        a24Validation.addRequired(objPostCode, true);
                        a24Validation.addMinLength(objPostCode, 1);
                        a24Validation.addMaxLength(objPostCode, 100);
                    }

                    this._formatPostCode();
                }
            } else if (sLookup === "objChildren.employer_to_date") {
                this.set("_bEmployerIsToDate", this.get(sLookup + ".mValue.bValue") === true);
            } else if (sLookup === "objChildren.referee_to_date") {
                this.set("_bRefereeIsToDate", this.get(sLookup + ".mValue.bValue") === true);
            } else if (sLookup === "objChildren.employer.address.post_code") {
                this._formatPostCode();
            }
        },

        _formatPostCode: function _formatPostCode() {
            var objCountryCode = this.get("objChildren")["employer.address.country_code"];
            var objPostCode = this.get("objChildren")["employer.address.post_code"];

            if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue) && (objCountryCode.mValue.sValue === "UK" || objCountryCode.mValue.sValue === "GB")) {
                if (!a24Core.isEmpty(objPostCode.mValue)) {
                    var sInputValue = objPostCode.mValue.trim();
                    if (!/\s/.test(sInputValue)) {
                        var sValue1 = sInputValue.slice(0, sInputValue.length - 3);
                        var sValue2 = sInputValue.slice(sInputValue.length - 3, sInputValue.length);
                        objPostCode.setValueFromOutside(sValue1.toUpperCase() + " " + sValue2.toUpperCase());
                    } else if (sInputValue.split(" ").length > 1) {
                        objPostCode.setValueFromOutside(sInputValue.replace(/  +/g, " ").toUpperCase());
                    }
                }
            }
        },

        _setCountyDropdown: function _setCountyDropdown(sCountryCode) {
            var arrCounties = null;
            if (sCountryCode === "ZA") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("saProvinces");
            } else if (sCountryCode === "UK" || sCountryCode === "GB") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("ukProvinces");
            } else if (sCountryCode === "US") {
                arrCounties = this.get("_objCandidateProfileEnumsService").getList("usProvinces");
            }

            this.set("_funcGetCountiesSuggestions", function (sQueryString, funcOnComplete) {
                var arrFilteredCounties = [];

                if (!a24Core.isEmpty(sQueryString)) {
                    for (var i = 0; i < arrCounties.length; i++) {
                        if (arrCounties[i].sLabel.toLowerCase().indexOf(sQueryString.toLowerCase()) !== -1) {
                            arrFilteredCounties.push(arrCounties[i]);
                        }
                    }
                } else {
                    arrFilteredCounties = arrCounties;
                }

                funcOnComplete(arrFilteredCounties);
            });
            this.set("_arrCounties", arrCounties);
        },

        _setCountiesFromCountryCode: function _setCountiesFromCountryCode(sCountryCode) {
            if (sCountryCode === "ZA" || sCountryCode === "UK" || sCountryCode === "GB" || sCountryCode === "US") {
                this.set("_bIsUkSaCountry", true);

                this.get("objChildren.province_uksa").clear();
                this._setCountyDropdown(sCountryCode);
            } else {
                this.set("_bIsUkSaCountry", false);
            }
        },

        _setupValidations: function _setupValidations() {
            var _this2 = this;

            var objValidationConfig = {};

            objValidationConfig["objChildren.employer.name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                a24Validation.addMinLength(objMixin, 1);
            };
            objValidationConfig["objChildren.employer.contact_number"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.employer.address.address_line1"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.employer.address.address_line2"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.employer.address.city"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.employer.address.post_code"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.province_uksa"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.province_other"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.employer.address.country_code"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.start_at"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                objMixin.get("arrValidation").pushObject(function () {
                    if (!_this2.get("objChildren.employer_to_date.mValue.bValue") && !a24Core.isEmpty(_this2.get("objChildren.end_at")) && !a24Core.isEmpty(_this2.get("objChildren.end_at.mValue"))) {
                        var objStartDate = a24DateManager.createDate(_this2.get("_objUserSession.objContextDetails.sTimezone"));
                        var objEndDate = a24DateManager.createDate(_this2.get("_objUserSession.objContextDetails.sTimezone"));
                        objStartDate.setDateFromBrowser(objMixin.get("mValue"), _this2.get("_objLibConstants.DATE_FORMAT"));
                        objEndDate.setDateFromBrowser(_this2.get("objChildren.end_at.mValue"), _this2.get("_objLibConstants.DATE_FORMAT"));
                        if (objEndDate.difference(objStartDate, a24Constants.MOMENT_TIME_DAYS) <= 0) {
                            return _this2.get("_objCandidateProfileStringsService").getTokenString("validationStartDateLessNotSameAsEndDate", objMixin.get("sFieldName"), _this2.get("objChildren.end_at.sFieldName"));
                        }
                    }
                });
            };
            objValidationConfig["objChildren.end_at"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.known_from"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
                objMixin.get("arrValidation").pushObject(function () {
                    if (!_this2.get("objChildren.referee_to_date.mValue.bValue") && !a24Core.isEmpty(_this2.get("objChildren.known_to")) && !a24Core.isEmpty(_this2.get("objChildren.known_to.mValue"))) {
                        var objStartDate = a24DateManager.createDate(_this2.get("_objUserSession.objContextDetails.sTimezone"));
                        var objEndDate = a24DateManager.createDate(_this2.get("_objUserSession.objContextDetails.sTimezone"));
                        objStartDate.setDateFromBrowser(objMixin.get("mValue"), _this2.get("_objLibConstants.DATE_FORMAT"));
                        objEndDate.setDateFromBrowser(_this2.get("objChildren.known_to.mValue"), _this2.get("_objLibConstants.DATE_FORMAT"));
                        if (objEndDate.difference(objStartDate, a24Constants.MOMENT_TIME_DAYS) <= 0) {
                            return _this2.get("_objCandidateProfileStringsService").getTokenString("validationStartDateLessNotSameAsEndDate", objMixin.get("sFieldName"), _this2.get("objChildren.known_to.sFieldName"));
                        }
                    }
                });
            };
            objValidationConfig["objChildren.known_to"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.relationship"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.position"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.profession"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.email_address"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.work_contact_number"] = function (objMixin) {
                objMixin.get("arrValidation").pushObject(function () {
                    if (a24Core.isEmpty(_this2.get("objChildren.work_contact_number.mValue")) && a24Core.isEmpty(_this2.get("objChildren.mobile_contact_number.mValue"))) {
                        return _this2.get("_objCandidateProfileStringsService").getString("validationPhoneOrMobileRequired");
                    }
                });
            };

            objValidationConfig["objChildren.mobile_contact_number"] = function (objMixin) {
                objMixin.get("arrValidation").pushObject(function () {
                    if (a24Core.isEmpty(_this2.get("objChildren.work_contact_number.mValue")) && a24Core.isEmpty(_this2.get("objChildren.mobile_contact_number.mValue"))) {
                        return _this2.get("_objCandidateProfileStringsService").getString("validationPhoneOrMobileRequired");
                    }
                });
            };

            this.set("objChildConfig", objValidationConfig);
        },

        _setCountriesDropdown: function _setCountriesDropdown() {
            var _this3 = this;

            this.set("_arrCountries", this.get("_objEnumsService").getList("country"));
            this.set("_funcGetCountriesSuggestions", function (sQueryString, funcOnComplete) {
                var arrCountries = _this3.get("_arrCountries");
                var arrFilteredCountries = [];

                if (!a24Core.isEmpty(sQueryString)) {
                    for (var i = 0; i < arrCountries.length; i++) {
                        if (arrCountries[i].sLabel.toLowerCase().indexOf(sQueryString.toLowerCase()) !== -1) {
                            arrFilteredCountries.push(arrCountries[i]);
                        }
                    }
                } else {
                    arrFilteredCountries = arrCountries;
                }

                funcOnComplete(arrFilteredCountries);
            });
        },

        _setupForm: function _setupForm() {
            var _this4 = this;

            if (this.get("_bServiceCallsDone") && this.get("_bEmployerContactNumberInputsReady") && this.get("_bWorkContactNumberInputsReady") && this.get("_bMobileContactNumberInputsReady") && this.get("_bFaxNumberInputsReady")) {
                if (this.get("_bAllowAddEdit") && !this.get("_bIsAgency")) {
                    this.get("_objFabButtonService").showFabButton(function () {
                        _this4._showAddEditRefereePopup();
                    });
                }
                this.set("_arrDisplayCandidateReferees", this._prepareForDisplay(this.get("_arrCandidateReferees")));
                this.set("_bLoading", false);
            }
        },

        _getCandidateReferences: function _getCandidateReferences() {
            var _this5 = this;

            if (!this.get("objSettingsRestService.objSettings.professional_reference_editable") || a24Core.isEmpty(this.get("objSettingsRestService.objSettings.agency_groups"))) {
                this.set("_bAllowAddEdit", false);
                return;
            } else {
                this.set("_bAllowAddEdit", true);
            }

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            this.get("objCandidateRefereeRestService").getReferees(this, "_objCandidateRefereeServicePromise", this.get("sCandidateId"), function (arrResponse) {
                _this5.set("_bServiceCallsDone", true);
                if (a24Core.isEmpty(arrResponse)) {
                    _this5.set("_arrCandidateReferees", []);
                } else {
                    _this5.set("_arrCandidateReferees", arrResponse);
                }
                _this5._setupForm();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this5.set("_bLoading", false);
                _this5.set("_bServerError", true);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                sortBy: {
                    mValue: "name"
                }
            });
        },

        _prepareForDisplay: function _prepareForDisplay(arrResponse) {
            var _this6 = this;

            var objThis = this;
            var arrCountries = this.get("_objEnumsService").getList("country");

            var funcCardActionEditExecute = function funcCardActionEditExecute(objItem) {
                return function () {
                    objThis._onRefereeEditClicked(objItem);
                };
            };

            var funcCardActionDeleteExecute = function funcCardActionDeleteExecute(objItem) {
                return function () {
                    objThis._onRefereeDeleteClicked(objItem);
                };
            };

            return arrResponse.map(function (objReferee) {
                var bIsProfessional = objReferee.type === _this6.get("_objCandidateProfileEnumsService.referenceTypes.PROFESSIONAL.backend");

                var arrActions = [];

                if (!_this6.get("_bIsAgency")) {
                    arrActions.push({
                        sTitle: _this6.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: funcCardActionEditExecute(objReferee)
                    });
                }

                if (_this6.get("_bIsAgencyGroup")) {
                    arrActions.push({
                        sTitle: _this6.get("_objStringsService").getString("remove"),
                        sIcon: "delete",
                        executeCardAction: funcCardActionDeleteExecute(objReferee)
                    });
                }

                var arrEmployerItems = null;
                var sStartDate = null;
                var sEndDate = null;

                if (!a24Core.isEmpty(objReferee.start_at)) {
                    var objStartDate = a24DateManager.createDate(_this6.get("_objUserSession.objContextDetails.sTimezone"));
                    objStartDate.setDateFromRest(objReferee.start_at, true);
                    sStartDate = objStartDate.getDateFormat();
                }

                if (objReferee.current || a24Core.isEmpty(objReferee.end_at)) {
                    sEndDate = _this6.get("_objCandidateProfileStringsService").getString("current");
                } else {
                    var objEndDate = a24DateManager.createDate(_this6.get("_objUserSession.objContextDetails.sTimezone"));
                    objEndDate.setDateFromRest(objReferee.end_at, true);
                    sEndDate = objEndDate.getDateFormat();
                }

                if (!a24Core.isEmpty(objReferee.employer)) {
                    var sEmployerContactNumber = null;

                    var arrEmployerAddress = [];

                    arrEmployerItems = [{
                        sLabel: _this6.get("_objCandidateProfileStringsService").getString("employerNameCompany"),
                        sValue: objReferee.employer.name,
                        bText: true,
                        bHideEmpty: false
                    }];

                    var sAddressLink = "";
                    var sLocationLabel = "";
                    if (!a24Core.isEmpty(objReferee.employer.address.address_line1)) {
                        sAddressLink = sAddressLink + objReferee.employer.address.address_line1;
                        sLocationLabel = objReferee.employer.address.address_line1;
                    }
                    if (!a24Core.isEmpty(objReferee.employer.address.address_line2)) {
                        sAddressLink = sAddressLink + " " + objReferee.employer.address.address_line2;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objReferee.employer.address.address_line2;
                        } else {
                            arrEmployerAddress.push(objReferee.employer.address.address_line2);
                        }
                    }
                    if (!a24Core.isEmpty(objReferee.employer.address.city)) {
                        sAddressLink = sAddressLink + " " + objReferee.employer.address.city;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objReferee.employer.address.city;
                        } else {
                            arrEmployerAddress.push(objReferee.employer.address.city);
                        }
                    }
                    if (!a24Core.isEmpty(objReferee.employer.address.province)) {
                        sAddressLink = sAddressLink + " " + objReferee.employer.address.province;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objReferee.employer.address.province;
                        } else {
                            arrEmployerAddress.push(objReferee.employer.address.province);
                        }
                    }
                    if (!a24Core.isEmpty(objReferee.employer.address.country_code)) {
                        var objCountry = arrCountries.findBy("sValue", objReferee.employer.address.country_code.toUpperCase());
                        sAddressLink = sAddressLink + " " + objCountry.sLabel;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objCountry.sLabel;
                        } else {
                            arrEmployerAddress.push(_this6.get("_objEnumsService").getFrontendValue("country", objReferee.employer.address.country_code.toUpperCase()));
                        }
                    }
                    if (!a24Core.isEmpty(objReferee.employer.address.post_code)) {
                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objReferee.employer.address.post_code;
                        } else {
                            arrEmployerAddress.push(objReferee.employer.address.post_code);
                        }
                    }

                    arrEmployerItems.push({
                        sLabel: _this6.get("_objCandidateProfileStringsService").getString("employerAddress"),
                        sValue: a24Core.getSafeStringLink(Ember, sLocationLabel, sAddressLink, "address"),
                        bText: true,
                        bHideEmpty: false
                    });

                    for (var i = 0; i < arrEmployerAddress.length; i++) {
                        arrEmployerItems.push({
                            sLabel: null,
                            sValue: arrEmployerAddress[i],
                            bText: true
                        });
                    }

                    if (!a24Core.isEmpty(objReferee.employer.contact_number)) {
                        var sFormattedNumber = _this6.get("objChildren")["employer.contact_number"].formatNumberForDisplay(objReferee.employer.contact_number);
                        sEmployerContactNumber = a24Core.getSafeStringLink(Ember, sFormattedNumber, objReferee.employer.contact_number, "phone");
                    }

                    arrEmployerItems.push({
                        sLabel: _this6.get("_objCandidateProfileStringsService").getString("phone"),
                        sValue: sEmployerContactNumber,
                        bText: true,
                        bHideEmpty: false
                    });

                    arrEmployerItems.push({
                        sLabel: _this6.get("_objCandidateProfileStringsService").getString("startDate"),
                        sValue: sStartDate,
                        bDate: true,
                        bHideEmpty: false
                    });

                    arrEmployerItems.push({
                        sLabel: _this6.get("_objCandidateProfileStringsService").getString("endDate"),
                        sValue: sEndDate,
                        bDate: true,
                        bHideEmpty: false
                    });
                }

                var sWorkContactNumber = null;
                var sMobileContactNumber = null;
                var sFaxNumber = null;

                if (!a24Core.isEmpty(objReferee.work_contact_number)) {
                    sWorkContactNumber = a24Core.getSafeStringLink(Ember, _this6.get("objChildren")["work_contact_number"].formatNumberForDisplay(objReferee.work_contact_number), objReferee.work_contact_number, "phone");
                }

                if (!a24Core.isEmpty(objReferee.mobile_contact_number)) {
                    sMobileContactNumber = a24Core.getSafeStringLink(Ember, _this6.get("objChildren")["mobile_contact_number"].formatNumberForDisplay(objReferee.mobile_contact_number), objReferee.mobile_contact_number, "phone");
                }

                if (!a24Core.isEmpty(objReferee.fax_number)) {
                    sFaxNumber = a24Core.getSafeStringLink(Ember, _this6.get("objChildren")["fax_number"].formatNumberForDisplay(objReferee.fax_number), objReferee.fax_number, "phone");
                }

                var arrRefereeItems = [{
                    sLabel: _this6.get("_objCandidateProfileStringsService").getString("referenceType"),
                    sValue: _this6.get("_objCandidateProfileEnumsService").getFrontendValue("referenceTypes", objReferee.type),
                    bText: true,
                    bHideEmpty: false
                }, {
                    sLabel: _this6.get("_objCandidateProfileStringsService").getString("refereeName"),
                    sValue: objReferee.name,
                    bText: true,
                    bHideEmpty: false
                }, {
                    sLabel: _this6.get("_objCandidateProfileStringsService").getString("position"),
                    sValue: objReferee.position,
                    bText: true,
                    bHideEmpty: false
                }];

                if (!bIsProfessional) {
                    arrRefereeItems.push({
                        sLabel: _this6.get("_objCandidateProfileStringsService").getString("knownFrom"),
                        sValue: sStartDate,
                        bDate: true,
                        bHideEmpty: false
                    }, {
                        sLabel: _this6.get("_objCandidateProfileStringsService").getString("knownTo"),
                        sValue: sEndDate,
                        bDate: true,
                        bHideEmpty: false
                    });
                }

                arrRefereeItems.push({
                    sLabel: _this6.get("_objStringsService").getString("email"),
                    sValue: objReferee.email_address,
                    bText: true,
                    bHideEmpty: false
                }, {
                    sLabel: _this6.get("_objCandidateProfileStringsService").getString("phone"),
                    sValue: sWorkContactNumber,
                    bText: true,
                    bHideEmpty: false
                }, {
                    sLabel: _this6.get("_objCandidateProfileStringsService").getString("mobile"),
                    sValue: sMobileContactNumber,
                    bText: true,
                    bHideEmpty: false
                }, {
                    sLabel: _this6.get("_objCandidateProfileStringsService").getString("fax"),
                    sValue: sFaxNumber,
                    bText: true,
                    bHideEmpty: false
                });

                var objItem = {
                    sLabel: _this6.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", objReferee.name, bIsProfessional ? _this6.get("_objCandidateProfileStringsService").getString("profRef") : _this6.get("_objCandidateProfileStringsService").getString("charRef")),
                    sExtra: bIsProfessional ? objReferee.employer.name : objReferee.relationship,
                    sIcon: bIsProfessional ? "business_center" : "person",
                    arrActions: arrActions,
                    arrRefereeItems: [{
                        arrItems: arrRefereeItems
                    }]
                };

                if (!a24Core.isEmpty(arrEmployerItems)) {
                    objItem.arrEmployerItems = [{
                        arrItems: arrEmployerItems
                    }];
                }

                return objItem;
            });
        },

        _onRefereeDeleteClicked: function _onRefereeDeleteClicked(objItem) {
            this.set("_sRefereeId", objItem._id);
            this.set("_bShowDeleteConfirm", true);
            this.set("_bPopupDeleteLoading", false);
        },

        _onRefereeEditClicked: function _onRefereeEditClicked(objItem) {
            this.set("_sRefereeId", objItem._id);
            this._showAddEditRefereePopup(objItem);
        },

        _populateInputFields: function _populateInputFields(objData) {
            var objType = this.get("_arrReferenceTypes").findBy("sValue", objData.type);

            if (!a24Core.isEmpty(objType)) {
                this.set("_objSelectedReferenceType", Ember.copy(objType));
                this.set("_bIsProfessionalType", false);
            } else {
                this.set("_objSelectedReferenceType", null);
                this.set("_bIsProfessionalType", true);
            }

            if (this.get("_bIsProfessionalType")) {
                if (!a24Core.isEmpty(objData.employer)) {
                    if (!a24Core.isEmpty(objData.employer.name)) {
                        this.get("objChildren")["employer.name"].setValueFromOutside(objData.employer.name);
                    }
                    if (!a24Core.isEmpty(objData.employer.contact_number)) {
                        this.get("objChildren")["employer.contact_number"].setValueFromOutside(objData.employer.contact_number);
                    }
                    if (!a24Core.isEmpty(objData.employer.address)) {
                        if (!a24Core.isEmpty(objData.employer.address.address_line1)) {
                            this.get("objChildren")["employer.address.address_line1"].setValueFromOutside(objData.employer.address.address_line1);
                        }
                        if (!a24Core.isEmpty(objData.employer.address.address_line2)) {
                            this.get("objChildren")["employer.address.address_line2"].setValueFromOutside(objData.employer.address.address_line2);
                        }
                        if (!a24Core.isEmpty(objData.employer.address.city)) {
                            this.get("objChildren")["employer.address.city"].setValueFromOutside(objData.employer.address.city);
                        }
                        if (!a24Core.isEmpty(objData.employer.address.post_code)) {
                            this.get("objChildren")["employer.address.post_code"].setValueFromOutside(objData.employer.address.post_code);
                        }

                        // Country
                        var sCountryCode = objData.employer.address.country_code;

                        var objCountry = this.get("_arrCountries").findBy("sValue", sCountryCode);

                        if (!a24Core.isEmpty(objCountry)) {
                            this.get("objChildren")["employer.address.country_code"].setValueFromOutside(Ember.copy(objCountry));
                        }

                        // County
                        this._setCountiesFromCountryCode(sCountryCode);

                        if ((sCountryCode === "ZA" || sCountryCode === "UK" || sCountryCode === "GB" || sCountryCode === "US") && !a24Core.isEmpty(objData.employer.address.province)) {
                            var objCounty = this.get("_arrCounties").findBy("sValue", objData.employer.address.province);

                            if (!a24Core.isEmpty(objCounty)) {
                                this.get("objChildren")["province_uksa"].setValueFromOutside(Ember.copy(objCounty));
                            }
                        } else {
                            this.get("objChildren")["province_other"].setValueFromOutside(objData.employer.address.province);
                        }
                    }
                }

                if (!a24Core.isEmpty(objData.start_at)) {
                    var objStartDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                    objStartDate.setDateFromRest(objData.start_at, true);
                    this.get("objChildren.start_at").setValueFromOutside(objStartDate.getDateFormat());
                }
                if (!a24Core.isEmpty(objData.end_at)) {
                    var objEndDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                    objEndDate.setDateFromRest(objData.end_at, true);
                    this.get("objChildren.end_at").setValueFromOutside(objEndDate.getDateFormat());
                }
                if (!a24Core.isEmpty(objData.position)) {
                    this.get("objChildren")["position"].setValueFromOutside(objData.position);
                }

                this.set("_bEmployerIsToDate", objData.current === true);
            } else {
                this.set("_bRefereeIsToDate", objData.current === true);

                if (!a24Core.isEmpty(objData.relationship)) {
                    this.get("objChildren")["relationship"].setValueFromOutside(objData.relationship);
                }
                if (!a24Core.isEmpty(objData.position)) {
                    this.get("objChildren")["profession"].setValueFromOutside(objData.position);
                }
                if (!a24Core.isEmpty(objData.start_at)) {
                    var objKnownFromDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                    objKnownFromDate.setDateFromRest(objData.start_at, true);
                    this.get("objChildren.known_from").setValueFromOutside(objKnownFromDate.getDateFormat());
                }
                if (!a24Core.isEmpty(objData.end_at)) {
                    var objKnownToDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                    objKnownToDate.setDateFromRest(objData.end_at, true);
                    this.get("objChildren.known_to").setValueFromOutside(objKnownToDate.getDateFormat());
                }
            }

            if (!a24Core.isEmpty(objData.name)) {
                this.get("objChildren")["name"].setValueFromOutside(objData.name);
            }
            if (!a24Core.isEmpty(objData.email_address)) {
                this.get("objChildren")["email_address"].setValueFromOutside(objData.email_address);
            }
            if (!a24Core.isEmpty(objData.work_contact_number)) {
                this.get("objChildren")["work_contact_number"].setValueFromOutside(objData.work_contact_number);
            }
            if (!a24Core.isEmpty(objData.mobile_contact_number)) {
                this.get("objChildren")["mobile_contact_number"].setValueFromOutside(objData.mobile_contact_number);
            }
            if (!a24Core.isEmpty(objData.fax_number)) {
                this.get("objChildren")["fax_number"].setValueFromOutside(objData.fax_number);
            }
        },

        _showAddEditRefereePopup: function _showAddEditRefereePopup(objReferee) {
            var _this7 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_bIsAdd", true);
            this.set("_sAddEditRefereeHeader", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("referee")));

            if (!a24Core.isEmpty(objReferee)) {
                this.set("_bIsAdd", false);
                this.set("_sAddEditRefereeHeader", this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("referee")));

                this._populateInputFields(objReferee);
            }

            this.set("_bShowAddEditRefereePopup", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this7)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this7.set("_objOriginalData", _this7.getPlainFormValueObject());
                });
            });
        },

        _doSuccess: function _doSuccess(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowAddEditRefereePopup", false);
            this._resetAllFields();
            this.set("_bAddEditRefereePopupLoading", false);
            this.set("_bShowDeleteConfirm", false);
            this.set("_bPopupDeleteLoading", false);

            this._getCandidateReferences();
        },

        _doError: function _doError(sCode, objError, sSnackbarMessage) {
            if (sCode === "ENUM_MISMATCH") {
                this.setResponseError([objError.arrPath.join(".")], "validationRestUnsupportedValue");
            } else if (sCode === "PATTERN" || sCode === "INVALID_FORMAT" || sCode === "INVALID_TYPE") {
                this.setResponseError([objError.arrPath.join(".")], "validationRestInvalidFormat");
            } else if (sCode === "MIN_LENGTH") {
                this.setResponseError([objError.arrPath.join(".")], "validationRestMinLength");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }
        },

        _getPayload: function _getPayload() {
            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (!a24Core.isEmpty(objPayload.start_at)) {
                var objStartAt = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objStartAt.setTimeToStartOfDay();
                objStartAt.setDateFromBrowser(objPayload.start_at, this.get("_objLibConstants.DATE_FORMAT"));
                objPayload.start_at = objStartAt.getDateStringForRest(true);
            }

            if (!a24Core.isEmpty(objPayload.known_from)) {
                var objKnownFrom = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objKnownFrom.setTimeToStartOfDay();
                objKnownFrom.setDateFromBrowser(objPayload.known_from, this.get("_objLibConstants.DATE_FORMAT"));
                objPayload.start_at = objKnownFrom.getDateStringForRest(true);
                delete objPayload.known_from;
            }

            if (!a24Core.isEmpty(objPayload.end_at)) {
                var objEndAt = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objEndAt.setTimeToEndOfDay();
                objEndAt.setDateFromBrowser(objPayload.end_at, this.get("_objLibConstants.DATE_FORMAT"));
                objPayload.end_at = objEndAt.getDateStringForRest(true);
            }

            if (!a24Core.isEmpty(objPayload.known_to)) {
                var objKnownTo = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objKnownTo.setTimeToEndOfDay();
                objKnownTo.setDateFromBrowser(objPayload.known_to, this.get("_objLibConstants.DATE_FORMAT"));
                objPayload.end_at = objKnownTo.getDateStringForRest(true);
                delete objPayload.known_to;
            }

            if (a24Core.isEmpty(objPayload.type)) {
                objPayload.type = this.get("_objCandidateProfileEnumsService.referenceTypes.PROFESSIONAL.backend");
            }

            if (!a24Core.isEmpty(objPayload.employer) && !a24Core.isEmpty(objPayload.employer.address)) {
                if (!a24Core.isEmpty(objPayload.province_uksa)) {
                    objPayload.employer.address.province = objPayload.province_uksa.sValue;
                    delete objPayload.province_uksa;
                } else if (!a24Core.isEmpty(objPayload.province_other)) {
                    objPayload.employer.address.province = objPayload.province_other;
                    delete objPayload.province_other;
                }

                if (!a24Core.isEmpty(objPayload.employer.address.country_code)) {
                    objPayload.employer.address.country_code = objPayload.employer.address.country_code.sValue;
                }
            }

            if (!a24Core.isEmpty(objPayload.profession)) {
                objPayload.position = objPayload.profession;
                delete objPayload.profession;
            }

            delete objPayload.employer_to_date;
            delete objPayload.referee_to_date;

            return objPayload;
        },

        _resetAllFields: function _resetAllFields() {
            this.set("_bEmployerIsToDate", false);
            this.set("_bRefereeIsToDate", false);
            this.set("_bIsUkSaCountry", false);
        },

        _onPopupRefereeAddEditCancel: function _onPopupRefereeAddEditCancel() {
            if (this.get("_bAddEditRefereePopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddEditRefereePopup", false);
                this._resetAllFields();
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },

        _onPopupRefereeAddEditSave: function _onPopupRefereeAddEditSave() {
            var _this8 = this;

            this.clearAllErrorMessages();

            if (!this.doFullValidation()) {
                var objPayload = this._getPayload();

                this.set("_bAddEditRefereePopupLoading", true);
                if (this.get("_bIsAdd")) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("referee")));

                    this.get("objCandidateRefereeRestService").addReferee(this, "_objCandidateRefereeServicePromise", this.get("sCandidateId"), objPayload, function () {
                        _this8._doSuccess(_this8.get("_objStringsService").getTokenString("successAddItem", _this8.get("_objCandidateProfileStringsService").getString("referee")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this8.set("_bAddEditRefereePopupLoading", false);

                        if (sCode === "404" || sCode === "409") {
                            _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objCandidateProfileStringsService").getString("addFailedRefreshPage"));
                        } else {
                            _this8._doError(sCode, objError, _this8.get("_objStringsService").getTokenString("failAddItem", _this8.get("_objCandidateProfileStringsService").getString("referee")));
                        }
                    }));
                } else {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("referee")));

                    this.get("objCandidateRefereeRestService").updateReferee(this, "_objCandidateRefereeServicePromise", this.get("sCandidateId"), this.get("_sRefereeId"), objPayload, function () {
                        _this8._doSuccess(_this8.get("_objStringsService").getTokenString("successUpdateItem", _this8.get("_objCandidateProfileStringsService").getString("referee")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this8.set("_bAddEditRefereePopupLoading", false);

                        if (sCode === "404" || sCode === "409") {
                            _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objStringsService").getString("updateFailedRefreshPage"));
                        } else {
                            _this8._doError(sCode, objError, _this8.get("_objStringsService").getTokenString("failUpdateItem", _this8.get("_objCandidateProfileStringsService").getString("referee")));
                        }
                    }));
                }
            }
        },

        _onPopupRefereeDeleteConfirm: function _onPopupRefereeDeleteConfirm() {
            var _this9 = this;

            this.set("_bPopupDeleteLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objCandidateProfileStringsService").getString("referee")));

            this.get("objCandidateRefereeRestService").deleteReferee(this, "_objCandidateRefereeServicePromise", this.get("sCandidateId"), this.get("_sRefereeId"), function () {
                _this9._doSuccess(_this9.get("_objStringsService").getTokenString("successRemoveItem", _this9.get("_objCandidateProfileStringsService").getString("referee")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this9.set("_bPopupDeleteLoading", false);
                if (sCode === "404" || sCode === "409") {
                    _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objStringsService").getString("removeFailedRefreshPage"));
                } else {
                    _this9._doError(sCode, objError, _this9.get("_objStringsService").getTokenString("failRemoveItem", _this9.get("_objCandidateProfileStringsService").getString("referee")));
                }
            }));
        },

        actions: {
            onPopupRefereeAddEditCancel: function onPopupRefereeAddEditCancel() {
                this._onPopupRefereeAddEditCancel();
            },
            onPopupRefereeAddEditSave: function onPopupRefereeAddEditSave() {
                this._onPopupRefereeAddEditSave();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.set("_bShowAddEditRefereePopup", false);
                this._resetAllFields();
            },
            onEmployerContactNumberInputFormTelNumberInitDone: function onEmployerContactNumberInputFormTelNumberInitDone() {
                if (!this.get("_bEmployerContactNumberInputsReady")) {
                    this.set("_bEmployerContactNumberInputsReady", true);
                    this._setupForm();
                }
            },
            onWorkContactNumberInputFormTelNumberInitDone: function onWorkContactNumberInputFormTelNumberInitDone() {
                if (!this.get("_bWorkContactNumberInputsReady")) {
                    this.set("_bWorkContactNumberInputsReady", true);
                    this._setupForm();
                }
            },
            onMobileContactNumberInputFormTelNumberInitDone: function onMobileContactNumberInputFormTelNumberInitDone() {
                if (!this.get("_bMobileContactNumberInputsReady")) {
                    this.set("_bMobileContactNumberInputsReady", true);
                    this._setupForm();
                }
            },
            onFaxNumberInputFormTelNumberInitDone: function onFaxNumberInputFormTelNumberInitDone() {
                if (!this.get("_bFaxNumberInputsReady")) {
                    this.set("_bFaxNumberInputsReady", true);
                    this._setupForm();
                }
            },
            onPopupDeleteClose: function onPopupDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                this._onPopupRefereeDeleteConfirm();
            }
        }
    });
});